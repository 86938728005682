.windows {
  width: 29.3vw;
  height: 16.43vw;
  position: absolute;
  left: 50%;
  top: calc(-2.78vw + 50%);
  transform: translate(-50%, -50%);
  background-image: url("./wallpaper.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0.1vw;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease-in-out;
}

.bt {
  width: 100%;
  height: 5.5%;
  bottom: 0;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(2px);
  left: 0;
  border-radius: 0 0 0.1vw 0.1vw;
}

.line{
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  height: 0.05vw;
  position: absolute;
  bottom: 5.5%;
}

.trueBt{
  width: 99.6%;
  height: 5.5%;
  bottom: 0;
  position: absolute;
  display: flex;
  align-items: center;
}

.trueBt img{
  width: 0.78vw;
  margin-left: 0.4%;
  cursor: pointer;
}

.trueBt .logopng{
  width: 0.78vw;
  height: 0.78vw;
  margin-left: 1.5%;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 20%;
}

.pageTerminal{
  width: 16vw;
  height: 12.8vw;
  background-color: rgba(0, 0, 0, 0.85);
  position: absolute;
  border-radius: 2.2%;
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  color: rgb(24, 245, 24);
  font-family: 'Courier New', Courier, monospace;
  overflow-y: auto;
}

.code::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.code::-webkit-scrollbar-track {
    background: #000;
}

.code::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 3px;
    border: 1px solid #555;
}

.code::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

.code {
    scrollbar-width: thin;
    scrollbar-color: #888 #000;
}

.abas{
  display: flex;
  font-size: 0.4vw;
  color: rgba(255, 255, 255, 0.7);
  width: 2vw;
  justify-content: center;
  align-items: center;
  border-radius: 8%;
  height: 0.4vw;
}

.head{
  display: flex;
  width: 95%;
  justify-content: space-between;
  align-items: center;
  height: 0.5vw;
}

.nameaba{
  font-size: 0.3vw;
  color: rgba(255, 255, 255, 0.7);
}

.icon-x{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0.2vw;
  padding-right: 0.2vw;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.icon-x:hover{
  background-color: red;
}

.code{
  width: 98%;
  height: 94%;
  background-color: black;
  font-size: 0.43vw;
  color: rgb(24, 245, 24);
  font-family: 'Courier New', Courier, monospace;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-y: auto;
  position: relative;
}

.message {
  margin-bottom: 5px;
}

.message.command {
  color: #00ff00;
}

.message.info {
  color: #00ff00;
}

.message.error {
  color: #ff0000;
}

.prompt {
  font-family: 'Courier New', Courier, monospace;
  font-size: 0.43vw;
  color: rgb(24, 245, 24);
  display: block;
  margin-bottom: 5px;
}

.input {
  width: 95%;
  padding: 5px;
  background-color: black;
  border: none;
  color: rgb(24, 245, 24);
  font-family: 'Courier New', Courier, monospace;
  font-size: 0.43vw;
  outline: none;
}

.loading {
  font-family: 'Courier New', Courier, monospace;
  font-size: 0.43vw;
  color: rgb(24, 245, 24);
}

a {
    color: #00ffff;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

.message{
  margin-left: 1%;
  margin-top: 1%;
}
