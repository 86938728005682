/* C:\SOLANA PROJETOS\PROJETO INSANO\app\src\windows\pages\Login.css */

.Login{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-image: url("../win7.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 29.4vw;
  height: 16.49vw;
  position: absolute;
  left: 50%;
  top: calc(0vw + 50%);
  transform: translate(-50%, -50%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 1px;
  z-index: 1000;
}

.icon{
  width: 2.2vw;
  height: 2.2vw;
  border-radius: 18%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.3);
  cursor: pointer;
  border: solid 1px rgba(0, 0, 0, 0.2);
}

.icon img{
  width: 2.3vw;
  height: 2.3vw;
  border-radius: 2px;
  border-radius: 6%;
}

.name{
  font-size: 0.45vw;
  color: rgba(255, 255, 255, 0.7);
  padding-top: 0.1vw;
  text-align: center;
  cursor: pointer;
}

.load{
  display: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.load .animation {
  width: 0.4vw;
  height: 0.4vw;
  border: 0.1vw solid rgba(255, 255, 255, 0.7);
  border-top: 0.1vw solid transparent;
  border-radius: 100%;
  animation: spin 2s linear infinite;
  margin-top: 20%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
